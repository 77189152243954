@font-face {
  font-family: "SFB";
  src: url("assets/fonts/iskcon-font/sfuitext-bold.otf") format("otf"),
    url("assets/fonts/iskcon-font/sfuitext-bold.ttf") format("truetype");
}

@font-face {
  font-family: "SFR";
  src: url("assets/fonts/iskcon-font/sfuitext-regular.otf") format("otf"),
    url("assets/fonts/iskcon-font/sfuitext-regular.ttf") format("truetype");
}

.html5-video-player video {
  width: 100% !important;
  height: 449px;
  left: 0px !important;
  top: 0px !important;
}

body {
  font-family: "SFR" !important;
}

.pat-cls {
  font-family: "SFB" !important;
}

.card-desk-h5 {
  font-family: "SFB" !important;
}

.desktop .owl-dots {
  display: block !important;
}

.desktop .owl-dots .owl-dot span {
  width: 11px !important;
  height: 6px !important;
}

.desktop .owl-dots .owl-dot.active span {
  background: #f6d25a;
  width: 14px !important;
}

@media (max-width: 767px) {
  .desc-para {
    font-size: 15px;
    font-weight: 400;
    color: #e8c36f;
    text-align: center !important;
    width: 95%;
    margin-left: 2%;
    margin-top: 5%;
  }
  .desc-para-pitru-paksha {
    font-size: 20px;
    font-weight: 480;
    color: #eebb45;
    /* text-align: center !important; */
    width: 90%;
    margin-left: 8%;
  }
  .otherAmount-pitru-paksha {
    display: flex;
     justify-content: center;
    align-items: center;
    width: 100%;
   
    
    

  }
  .desc-para-boat {
    font-size: 15px;
    font-weight: 400;
    color: #e8c36f;
    text-align: center !important;
    width: 99%;

    margin-top: 5%;
  }

  .dec-h5 {
    font-size: 20px;
    font-weight: 600;
    color: #e8c36f;
  }
}
/* laptop */
@media (min-width: 768px) {
  .seva-category {
    font-weight: 700;
    color: #e8c36f;
    margin-left: 6%;
  }
  .seva-category-description{
       font-weight:500;
       color: #e8c36f;
       margin-left: 10%;
       margin-right: 10%;
       text-align: center;
  }
  .krishnaImage {
    margin-top: 40%;
  }
  .krishnaImagePitruPaksha {
    margin-top: 1%;
  }
  .amountButtonDirection {
    display: flex;
    flex-direction: row;
    
    margin-top: 2%;
    justify-content: space-between;
    margin-right: 10%;
  }
  .otherAmount-pitru-paksha {
    display: flex;
     justify-content: center;
    align-items: flex-start;
    width: 100%;
    flex-direction: "column";
    box-sizing: "border-box";
    margin-left: 20%;
    

  }
 .annadanam {
    align-items: "center";
    justify-content: "center";
    display: "flex";
    font-weight: 600;
    color: #e8c36f;
    margin-left: 30%;
    flex-direction: "column";
 }
 .annadanam-tag {
    align-items: "center";
    justify-content: "center";
    display: "flex";
    flex-direction: "column";
    font-weight: 400;
    color: #e8c36f;
    font-size: 20px;
    margin-left: 33%;
 }   
 .annadanam-pitrupaksha {
  align-items: "center";
  justify-content: "center";
  display: "flex";
  font-weight: 700;
  color: #e8c36f;
  
  flex-direction: "column";
}
.annadanam-tag-pitrupaksha {
  align-items: "center";
  justify-content: "center";
  display: "flex";
  flex-direction: "column";
  font-weight: 400;
  color: #e8c36f;
  font-size: 20px;
  
  text-align: center !important;
}               
   
  
  .seva-description {
    margin-left: 4%;
    margin-top: 2%;
    font-size: 15px;
    margin-right: 5%;
  }
  .seva-container {
    margin-left: 5%;
  }
  .seva-container-pitru-paksha {
    
  }
  
  .seva-amount-description {
    font-size: 10px;
  }
  .textArea {
    width: 100%;
    height: 100%;
    padding: 12px 8px 8px 14px;
    display: block;

    border: none;
    border-bottom: 1px solid #fff;
    background: #fff;
    color: #2c2727;
    font-size: 14px !important;
    border-radius: 5px;
  }
}

@media (max-width: 767px) {
  .seva-category {
    font-weight: 600;
    color: #e8c36f;
    width: 100%;
    
  }
  .otherAmountSevaPitru{
  
      border-radius: 20px;
      border: none;
      padding: 15px;
      padding-left: 5%;
      outline: none;
      margin-left:20%;
      width: 100%;
      max-width: 200px;
      height: 65%;
      box-sizing: border-box,
   
  }
  .seva-category-description{
    font-weight:500;
    color: #e8c36f;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
}
  .audio-width {
    width: 100%;
  }
  .amountButtonDirection {
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    margin-right:19%
  }
  .krishnaImage {
    height: 60%;
    width: 60%;
    margin-left: 20%;
  }
  .krishnaImagePitruPaksha {
    height: 60%;
    width: 60%;
    margin-left: 20%;
  }
  .annadanam {
    align-items: "center";
    justify-content: "center";
    display: "flex";
    flex-direction: "column";
    font-weight: 600;
    color: #e8c36f;
 }
 .annadanam-tag {
    align-items: "center";
    justify-content: "center";
    display: "flex";
    flex-direction: "column";
    font-weight: 400;
    color: #e8c36f;
    font-size: 20px;

 }
 .annadanam-pitrupaksha {
  align-items: "center";
  justify-content: "center";
  display: "flex";
  font-weight: 800;
  color: #e8c36f;
  
  font-size: 25px;
  flex-direction: "column";
}
.annadanam-tag-pitrupaksha {
  align-items: "center";
  justify-content: "center";
  display: "flex";
  flex-direction: "column";
  font-weight: 400;
  color: #e8c36f;
  font-size: 20px;
  text-align: center !important;
 
}  
.seva-label2-pitru-paksha-container{
 
  margin-top: 2%;
}

  .seva-description {
    width: 100%;
    margin-top: 1%;
    font-size: 15px;
  }
  .seva-container {
    margin-left: 1%;
  }
  .seva-amount-description {
    font-size: 13px;
  }
  .otherAmountSeva {
    margin-top: 5%;
  }
  
  .textArea {
    width: 80%;
    height: 100%;
    padding: 12px 8px 8px 14px;
    display: block;

    border: none;
    border-bottom: 1px solid #fff;
    background: #fff;
    color: #2c2727;
    font-size: 14px !important;
    border-radius: 9px;
  }
}

@media (min-width: 768px) {
  .dec-h1 {
    color: #e8c36f;
    font-size: 40px;
    font-weight: 600;

    margin-top: 3%;
  }
  .seva-label2-pitru-paksha-container{
    margin-left: 0%;
    margin-top: 4%;
  }
  .date {
    font-weight: 600;
    font-size: 45px;
    margin-top: 2%;
    color: #d6a73a;
  }

  .from {
    font-weight: 600;
    font-size: 35px;
    margin-top: 2%;
    color: #d6a73a;
  }

  .arati {
    font-weight: 600;
    font-size: 23px;
    margin-top: 5%;
    color: #d6a73a;
  }
}

@media (max-width: 767px) {
  .dec-h1 {
    color: #e8c36f;
    font-size: 30px;
    font-weight: 600;

    margin-top: 2%;
  }

  .date {
    font-weight: 600;
    font-size: 30px;
    margin-top: 2%;
    color: #d6a73a;
  }

  .from {
    font-weight: 600;
    font-size: 28px;
    margin-top: 2%;
    color: #d6a73a;
  }

  .arati {
    font-weight: 600;
    font-size: 25px;
    margin-top: 2%;
    color: #d6a73a;
  }
}

@media (min-width: 768px) {
  .desc-para-pitru-paksha {
    font-size: 20px;
    font-weight: 480;
    color: #eebb45;
    /* text-align: center !important; */
    width: 90%;
    margin-left: 5%;
   
  }
  .desc-para {
    font-size: 20px;
    font-weight: 480;
    color: #eebb45;
    text-align: center !important;
    width: 70%;
    margin-left: 15%;
  }

  .desc-para-boat {
    font-size: 20px;
    font-weight: 480;
    color: #eebb45;
    text-align: center !important;
    width: 80%;
    margin-left: 10%;
  }

  .dec-h5 {
    font-size: 23px;
    font-weight: 600;
    color: #e8c36f;
    /* text-align: center !important; */
  }

  .h5Content {
    font-size: 23px;
    font-weight: 600;
    color: #e8c36f;
  }

  .pContent {
    font-size: 20px;
    font-weight: 480;
    color: #eebb45;
  }
}

@media (min-width: 768px) {
  .btn-h-donate {
    background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
    color: black;

    padding: 12px 25px;
    font-size: 15px;
    font-weight: 700;
    border: none;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .btn-h-donate {
    background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
    color: black;

    padding: 7px 10px;
    font-size: 15px;
    font-weight: 700;
    border: none;
    margin-top: 20px;
  }
}

a {
  text-decoration: none;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.header-donate {
  position: fixed;
  width: 100%;
  top: 0;
  height: 12%;
  z-index: 11111;
  background: linear-gradient(
    181deg,
    #00000099,
    #000000a3,
    #000000a1,
    #00000080
  );

  box-shadow: 0px 5px 20px 7px #000000a3;
}

@media (max-width: 767px) {
  .header-logo {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .header-logo {
    width: 12%;
    margin-top: -1%;
    margin-left: 2%;
  }
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

/* .desk-form-div {
    border-radius: 6px;
    padding: 32px 12px 32px 12px;
    color: #fff;
    background: linear-gradient(134deg, #302841, #652d4a);
    width:80%;
    
  
} */
.gal-div {
  background: #6c2e5d;
  padding: 20px 20px;
  border-radius: 20px;
  position: relative;
  z-index: 9;
  width: 80%;
  margin-left: 10%;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.gal-img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  cursor: pointer;
}

.image-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.image-column img {
  max-width: 33%;
  height: auto;
}

@media (max-width: 767px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
}

@media (min-width: 768px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .wrapper img {
    width: 90%;
    height: 90%;
    border-radius: 10%;
  }
}

@media (max-width: 767px) {
  .formwrapper {
    grid-template-columns: 1fr;
    display: grid;
    gap: 2%;
    margin-left: 20%;
    margin-top: 3%;
  }
  input[type="text"] {
    padding: 12px 8px 8px 14px;
    display: block;
    width: 80%;
    border: none;
    border-bottom: 1px solid #fff;
    background: #fff;
    color: #2c2727;
    font-size: 14px !important;
    border-radius: 9px;
    height: 90%;
  }
}

@media (min-width: 768px) {
  .formwrapper {
    display: grid;
    gap: 2%;
    margin-left: 0%;
    margin-top: 5%;
  }

  .group {
    margin-top: 1%;
  }
  input[type="text"] {
    padding: 12px 8px 8px 15px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #fff;
    background: #fff;
    color: #2c2727;
    font-size: 14px !important;
    border-radius: 9px;
    height: 90%;
  }
}

.desk-form-div .tax-note {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.desk-form-div-boat .tax-note {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.leftBottom-img-down {
  position: absolute;
  left: 0;
  bottom: 20%;
  transform: translateY(-60%);
  width: 12%;
}

.footer-logo {
  margin-top: -10%;
  width: 190px;
}

.footer-para {
  font-size: 17px;
  font-weight: 500;
  color: #bbb;
}

.footer-h5 {
  font-size: 16px;
  color: #ddd;
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  .desktop-video {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 767px) {
  .desktop-video {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.container {
  flex: 1;
  justify-content: center;
  margin-top: 2%;
  width: 100%;
}

@media (max-width: 767px) {
  .desk-form-div {
    width: 100%;
    max-width: 350px;
    border-radius: 6px;
    padding: 32px 12px 32px 12px;
    color: #fff;
    background: #302859;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .input-for-other-amount {
    border-radius: 20px;
    border: none;
    /* padding: 5px; */
    padding-left: 20px;
    outline: none;
    flex: 1;
    /* width: 5%;
         height: 5%; */
  }
}

@media (max-width: 767px) {
  .input-for-other-amount {
    border-radius: 20px;
    border: none;
    padding: 5px;
    padding-left: 20px;
    outline: none;
    flex: 1;
    width: 10%;
    height: 60%;
  }
  .input-margin-right-pitru {
    margin-right: 55%;
  }
  .mobile_number-pitru {
    margin-right: 30%;
  }
  .dob-pitru{
    margin-right: 35%;
  }
  .date-input-pitru {
    width: 80%;
    border: 1px solid #ced4da;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 1%;
    margin-right: 5%;
  }
}

@media (min-width: 768px) {
  .desk-form-div {
    border-radius: 6px;
    padding: 32px 12px 32px 12px;
    color: #fff;
    background: #302859;
    width: 95%;
  }
}

@media (min-width: 768px) {
  .desk-form-div-boat {
    border-radius: 6px;
    padding: 32px 12px 32px 12px;
    color: #fff;
    background: linear-gradient(134deg, #302841, #652d4a);
  }
}

.input-label::after {
  content: " *";
  color: red;
}

.date-input:focus {
  border-color: #5264ae;
}

@media (min-width: 768px) {
  .input-margin-right {
    margin-right: 85%;
  }
  .input-margin-right-pitru {
    margin-right: 75%;
  }
  .mobile_number {
    margin-right: 79%;
  }
  .mobile_number-pitru {
    margin-right: 63%;
  }
  .dob {
    margin-right: 82%;
  }
  .select-seva-pitru{
   
    color: white;
    font-size: 18px;
    margin-right: 40%;
  }
  .dob-pitru{
    margin-right: 67%;
  }
  .input-label-form-donar-boat {
    margin-left: -60%;
  }

  .input-label-form-mobile-boat {
    margin-top: 5%;
    margin-left: -53%;
  }

  .input-label-form-email-boat {
    margin-left: -71%;
  }

  .input-label-form-dob-boat {
    margin-top: 5%;
    margin-left: -63%;
  }

  .date-input-boat {
    padding: 10px;
    width: calc(100% - 55px);
    border: 1px solid #ced4da;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 0%;
    margin-left: -20%;
    height: 60%;
  }

  .input-label-form-donar {
    margin-left: -70%;
  }

  .input-label-form-mobile {
    margin-top: 5%;
    margin-left: -67%;
  }

  .input-label-form-email {
    margin-left: -79%;
  }

  .input-label-form-dob {
    margin-top: 5%;
    margin-left: -73%;
  }

  .date-input {
    padding: 5px;
    width: calc(100% - 120px);
    border: 1px solid #ced4da;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 0%;
    margin-left: -20%;
    height: 60%;
  }
  .date-input-rath {
    width: 60%;
    border: 1px solid #ced4da;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 0%;
    margin-left: -40%;
  }
  .date-input-pitru {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 1%;
    margin-left: 0%;
  }
}

/* @media (max-width: 767px) {
    .custom-phone-input {
      width: 100%;
    }
  
    .custom-phone-input-container {
      height: auto;
    }
  }
  @media (min-width: 768px) {
    .custom-phone-input {
      width: 700px;
    }
  
    
  } */

@media (max-width: 767px) {
  .input-margin-right {
    margin-right: 57%;
    font-size: 14px;
  }
  .mobile_number {
    margin-right: 38%;
    font-size: 14px;
  }
  .dob {
    margin-right: 42%;
    font-size: 14px;
  }
  .input-label-form-donar {
    margin-left: -60%;
  }

  .input-label-form-mobile {
    margin-left: -55%;
  }

  .input-label-form-email {
    margin-left: -72%;
  }

  .input-label-form-dob {
    margin-left: -65%;
  }

  .date-input {
    padding: 4px;
    width: calc(100% - 50px);
    border: 1px solid #ced4da;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 0%;
    margin-left: -20%;
  }
  .date-input-rath {
    padding: 5px;
    width: 80%;
    border: 1px solid #ced4da;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 0%;
    margin-left: -20%;
    height: 60%;
  }
  .input-label-form-donar-boat {
    margin-left: -60%;
  }

  .input-label-form-mobile-boat {
    margin-left: -55%;
  }

  .input-label-form-email-boat {
    margin-left: -72%;
  }

  .input-label-form-dob-boat {
    margin-left: -65%;
  }

  .date-input-boat {
    padding: 4px;
    width: calc(100% - 65px);
    border: 1px solid #ced4da;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 0%;
    margin-left: -20%;
  }
}

.error {
  margin-left: 20%;
}

.seva {
  font-weight: 550;
  font-family: "SFB" !important;
  color: #ffffff;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  accent-color: yellow;
}

@media (min-width: 768px) {
  .seva-label1 {
    font-weight: 600;
    font-size: 20px;
    padding-left: 6px;
  }

  .seva-label2 {
    font-weight: 600;
    font-size: 20px;
    padding-left: 6px;
    
   
    
  }
  .seva-label2-pitru-paksha {
    font-weight: 600;
    font-size: 20px;
    padding-left: 6px;
    text-decoration: underline;
    
  }
}

@media (max-width: 767px) {
  .seva-label1 {
    font-weight: 700;
    font-size: 16px;
    padding-left: 6px;
    color: #ffffff;
  }

  .seva-label2 {
    font-weight: 600;
    font-size: 21px;
    
    color: #ffffff;

  }
  .seva-label2-pitru-paksha {
    font-weight: 600;
    font-size: 21px;
    text-decoration: underline;
    color: #ffffff;

    text-align: 'center';
  }
}

.sevas {
  margin-top: 4%;
}

.checkbox-btn {
  border-radius: 20px;
  margin-left: 3%;
  margin-top: 2%;
  padding: 5px 20px;
}

.time {
  font-weight: 700;
  font-size: 35px;

  color: white;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-section {
  position: relative;
  z-index: 1;
}

@media (min-width: 768px) {
  .banner-image {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 4%;
    padding: 1%;
  }
}

@media (max-width: 767px) {
  .banner-image {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 23%;
    padding: 1%;
  }
}

.banner-img {
  width: 100%;
  height: auto;
  display: block;
}

.gyaanDaan{
  color: #FFFFFF;
  font-family:  "Sans-serif";
    font-size: 50px;
    font-weight: 600;
    -webkit-text-stroke-color: #000;
    stroke: #000;
    text-align: center;
}
.benefitsText{
  text-align:left;
    color: #ffffff;
    margin-left: 13%;
    margin-right: 13%;
    margin-top:1%;
    font-size: 20px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 1.3px;
}
.benefitsTextCenter{
  text-align:center;
  color: #FFFFFF;
    margin-left: 10%;
    margin-right: 10%;
    margin-top:1%;
    font-size: 27px;
    font-weight: 800;
    font-family: "cursive", "Lucida Handwriting";
    line-height: 35px;
    letter-spacing: 1.3px;
}
@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-80px);
  }
}
.bookImage {
 margin:1%;
  position: absolute;
  animation: bounce 1s infinite alternate;
  background-color:  "#ffffff";
}
.label{
  
    color: #FFFFFF;
    font-family:  "Sans-serif";
      font-size: 20px;
      font-weight: 600;
      -webkit-text-stroke-color: #000;
      stroke: #000;
      text-align: center;
  
}
.scanText{
  color: #d6a73a;
  font-family:  "Sans-serif";
    font-size: 30px;
    font-weight: 600;
    margin-top: 10%;
    -webkit-text-stroke-color: #000;
    stroke: #000;
    text-align: center;
}
.bankDetailsText{
  color: black;
  font-family:  "Sans-serif";
    font-size: 30px;
    font-weight: 600;
  
    -webkit-text-stroke-color: #000;
    stroke: #000;
    text-align: center;
}
.receiptText{
  color: #d6a73a;
  font-family:  "Sans-serif";
    font-size: 30px;
    font-weight: 600;
 
    -webkit-text-stroke-color: #000;
    stroke: #000;
    text-align: center;
}
.galleryi{
  --s: 150px; /* control the size */
  --g: 10px;  /* control the gap */
  --f: 1.5;   /* control the scale factor */
  
  display: grid;
  gap: var(--g);
  width: calc(3*var(--s) + 2*var(--g));
  aspect-ratio: 1;
  grid-template-columns: repeat(3,auto);
  transform: rotate(0deg);
  justify-items: center; 
  margin: 0 auto; 
  width: 70%;
  margin-top: 90px;
}

.galleryi > img {
  width: 0;
  height: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  cursor: pointer;
  filter: grayscale(80%);
  transition: .35s linear;
  transform: rotate(0deg);
}

.galleryi img:hover{
  filter: grayscale(0);
  width:  calc(var(--s)*var(--f));
  height: calc(var(--s)*var(--f));
  transform: rotate(0deg);
}



/* Add responsiveness */
@media (max-width: 768px) {
.galleryi {
    --s: 100px; /* Adjust size for smaller screens */
    --g: 8px;   /* Reduce gap */
    
  
}
}

@media (max-width: 480px) {
.galleryi {
    --s: 80px; /* Further reduce size for very small screens */
    --g: 5px;
    margin-top: 20px; /* Adjust spacing */
}
}