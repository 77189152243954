
@media (min-width: 768px) {
  .btn-h-donate {
    background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
    color: black;

    padding: 12px 25px;
    font-size: 15px;
    font-weight: 700;
    border: none;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .btn-h-donate {
    background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
    color: black;

    padding: 7px 10px;
    font-size: 15px;
    font-weight: 700;
    border: none;
    margin-top: 20px;
  }
}

a {
  text-decoration: none;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.header-donate {
  position: fixed;
  width: 100%;
  top: 0;
  height: 12%;
  z-index: 11111;
  background: linear-gradient(
    181deg,
    #00000099,
    #000000a3,
    #000000a1,
    #00000080
  );

  box-shadow: 0px 5px 20px 7px #000000a3;
}

@media (max-width: 767px) {
  .header-logo {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .header-logo {
    width: 12%;
    margin-top: -1%;
    margin-left: 2%;
  }
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}


/* Card */
.card {
  border-radius: 10px;
  filter: drop-shadow(0 5px 10px 0 #ffffff);
  width: 90%; /* Use percentage for responsiveness */
  max-width: 900px; /* Maximum width for larger screens */
  height: auto; /* Allow height to adjust dynamically */
  background-color: #ffffff;
  padding: 20px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: 0.6s ease-in;
  box-sizing: border-box; /* Include padding in width/height */
  text-align: center; /* Center-align text */
}

/* Card Before Element */
.card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -15px;
  right: -15px;
  background: #7952b3;
  height: 350px;
  width: 30px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

/* Hover Effects */
.card:hover::before {
  transition-delay: 0.5s;
  transform: scale(100);
}

.card:hover {
  color: #ffffff;
}

/* Card Content */
.card p {
  padding: 10px 0;
}






/* Responsive Design */
@media (max-width: 1200px) {
  .header-logo {
    height: 70px; /* Slightly smaller logo */
  }

  .btn-h-donate {
    font-size: 14px; /* Adjust button font size */
    padding: 6px 8px; /* Adjust padding */
  }
}

@media (max-width: 768px) {
  .header-donate {
    height: 10%; /* Adjust header height for smaller screens */
  }

  .header-logo {
    height: 60px; /* Smaller logo */
  }

  .btn-h-donate {
    font-size: 13px; /* Smaller font size */
    padding: 5px 7px; /* Adjust padding */
  }
  .container {
    height: auto; /* Adjust height for smaller screens */
    padding: 20px; /* Increase padding for mobile view */
  }

  .card {
    width: 100%; /* Use full width */
    padding: 15px; /* Adjust padding */
  }

  .card p {
    font-size: 14px; /* Slightly smaller text for readability */
  }
}

@media (max-width: 480px) {
  .header-donate {
    height: auto; /* Allow height to adjust dynamically */
    padding: 10px; /* Add padding for smaller screens */
  }

  .header-logo {
    height: 50px; /* Smaller logo for mobile */
  }

  .btn-h-donate {
    font-size: 12px; /* Smaller button text */
    padding: 4px 6px; /* Adjust padding */
  }

  .marquee {
    font-size: 10px; /* Reduce marquee text size */
  }
  .card {
    padding: 10px; /* Reduce padding for compact layout */
  }

  .card p {
    font-size: 12px; /* Smaller text for very small screens */
  }
}

/* Import Google Font */
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600&display=swap");

/* General Reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}



/* Import Google Font */

.ag-format-container {
  width: 1142px;
  margin: 0 auto;
}



.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-decoration: none;
  padding: 50px 0;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);

  margin: 0 15px 30px;
  text-decoration: none;
  overflow: hidden;

  border-radius: 28px;
}
.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  text-decoration: none;
  overflow: hidden;

  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color:black;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  min-height: 87px;
  margin: 0 0 25px;

  overflow: hidden;

  font-weight: bold;
  font-size: 30px;
  color: black;
  text-decoration: none;
  z-index: 2;
  position: relative;
}
.ag-courses-item_date-box {
  font-size: 18px;
  color:black;
  text-decoration: none;
  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;
  text-decoration: none;
  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;
  text-decoration: none;
  border-radius: 50%;

  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}
.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}
.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #e44002;
}
.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #952aff;
}
.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
  background-color: #cd3e94;
}
.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
  background-color: #4c49ea;
}

.body{
  text-decoration: none;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}

  
  /* Custom Animation */
  @keyframes slideIn {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
 
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* General container */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 10% auto;
  max-width: 1200px;
  font-family: 'Arial', sans-serif;
}

/* Left section */
.left-container {
  flex: 1;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.left-container h1 {
  font-size: 2rem;
  color: #333;
}

.left-container .puppy img {
  max-width: 100%;
  border-radius: 10px;
}

/* Right section */
.right-container {
  flex: 2;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 30px;
  margin-left: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.right-container header h1 {
  font-size: 1.5rem;
  color: #444;
  margin-bottom: 10px;
}

.left-container h1 p {
  font-size: 1rem;
  color: #666;
}

.right-container h2 {
  font-size: 1.5rem;
  color: #444;
  margin: 20px 0;
}

.set {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.pets-name {
  flex: 1;
  min-width: 250px;
  margin-bottom: 15px; /* Ensures consistent spacing between fields */
}

.pets-name label, .pets-name-amout label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.pets-name input, .pets-name textarea, .pets-name select,
.pets-name-amout input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 5px;
  box-sizing: border-box; /* Ensures padding and border are included in the element's width */
  height: 40px; /* Uniform height for inputs */
}

.pets-name input:focus, .pets-name textarea:focus, .pets-name select:focus,
.pets-name-amout input:focus {
  border-color: #7952b3;
  outline: none;
}

.pets-name input[type="date"], .pets-name-amout input[type="date"] {
  height: 40px; /* Ensure consistent height for date input */
}

.pets-name textarea {
  resize: vertical; /* Allows vertical resizing only for textarea */
  height: 100px; /* Set a specific height for the textarea */
}

.pets-name input[type="tel"] {
  height: 40px; /* Ensure consistent height for phone input */
}

.amount-button-container {
  display: flex;
  gap: 10px;
}

.checkbox-btn, .btn, .btn-primary, .pets-name-amout input {
  padding: 10px 15px;
  background-color: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, border-color 0.3s;
}

.pets-name-amout input {
  height: 50px;
  padding: 10px 15px;
}

.set {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.pets-name-amout {
  width: 150px; /* Moves the second div to the left */
}


.checkbox-btn.active,.btn.active ,.btn-primary.active{
  background:linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
  color: black;
  border: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b) ;
}

.checkbox-btn:hover,.btn:hover,.btn-primary:hover {
  background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
}
.btn{
  background:linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
}

.amount-label {
  margin-top: 25px;
  font-size: 14px;
  color: black; 
  /* Change as needed */
}

/* Footer section */
footer p {
  font-size: 0.9rem;
  color: #666;
}

footer p a {
  color: #7952b3;
  text-decoration: none;
}

footer p a:hover {
  text-decoration: underline;
}

footer {
  text-align: center; /* Center the text */
  margin-top: 20px;
}

.btn-group {
  display: flex;
  justify-content: center;  /* Horizontally center the button */
  align-items: center;      /* Vertically center the button */
  margin-top: 20px;
}



@media (max-width: 768px) {
  .signup-container {
    flex-direction: column;
    align-items: stretch;
  }

  .right-container {
    margin-left: 0;
    margin-top: 20px;
  }
}
body {
  margin: 0;
  height: 100vh;
  display: grid;
  place-items: center;
 
}
/* Gallery */
.gallery {
  position: relative;
  width: 300px; /* Base width */
  height: 200px; /* Base height */
  transform-style: preserve-3d;
  animation: rotate 35s linear infinite;
  margin: auto; /* Center the gallery */
}

/* Animation */
@keyframes rotate {
  from {
    transform: perspective(1200px) rotateY(0deg);
  }
  to {
    transform: perspective(1200px) rotateY(360deg);
  }
}

/* Gallery Span */
.gallery span {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform: rotateY(calc(var(--i) * 45deg)) translateZ(380px);
}

/* Gallery Images */
.gallery span img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .gallery {
    width: 250px; /* Reduce width for larger tablets */
    height: 180px; /* Adjust height proportionally */
  }

  .gallery span {
    transform: rotateY(calc(var(--i) * 45deg)) translateZ(300px); /* Adjust translateZ */
  }
}

@media (max-width: 768px) {
  .gallery {
    width: 200px; /* Reduce width for tablets */
    height: 150px; /* Adjust height proportionally */
  }

  .gallery span {
    transform: rotateY(calc(var(--i) * 45deg)) translateZ(250px); /* Further reduce translateZ */
  }
}

@media (max-width: 480px) {
  .gallery {
    width: 150px; /* Smaller size for mobile */
    height: 100px; /* Adjust height for smaller screens */
  }

  .gallery span {
    transform: rotateY(calc(var(--i) * 45deg)) translateZ(200px); /* Reduce translateZ */
  }
}


@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext");








