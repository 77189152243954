
@media (min-width: 768px) {
    .btn-h-donate {
      background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
      color: black;
  
      padding: 12px 25px;
      font-size: 15px;
      font-weight: 700;
      border: none;
      margin-top: 30px;
    }
  }
  
  @media (max-width: 767px) {
    .btn-h-donate {
      background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
      color: black;
  
      padding: 7px 10px;
      font-size: 15px;
      font-weight: 700;
      border: none;
      margin-top: 20px;
    }
  }
  
  a {
    text-decoration: none;
  }
  
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .header-donate {
    position: fixed;
    width: 100%;
    top: 0;
    height: 12%;
    z-index: 11111;
    background: linear-gradient(
      181deg,
      #00000099,
      #000000a3,
      #000000a1,
      #00000080
    );
  
    box-shadow: 0px 5px 20px 7px #000000a3;
  }
  
  @media (max-width: 767px) {
    .header-logo {
      width: 60%;
    }
  }
  
  @media (min-width: 768px) {
    .header-logo {
      width: 12%;
      margin-top: -1%;
      margin-left: 2%;
    }
  }
  
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }

  @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bree+Serif&family=EB+Garamond:ital,wght@0,500;1,800&display=swap');

  
  #container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 15px 30px 1px grey;
    background: rgba(255, 255, 255, 0.9);
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    margin: 5em auto;
    width: 80%;
    max-width: 1200px;
    height: auto;
    padding: 20px;
    flex-wrap: wrap;
  }
  
  .product-details {
    flex: 1;
    padding: 20px;
  }
  
  .product-details h1 {
    font-family: 'Bebas Neue', cursive;
    font-size: 36px;
    color: #344055;
    margin: 0;
  }
  
  .product-details .quote {
    font-size: 20px;
    font-style: italic;
    color: #344055;
    margin: 15px 0;
  }
  
  .product-details .information {
    text-align: justify;
    font-size: 18px;
    color: #7d7d7d;
    margin: 10px 0;
  }
  
  .product-image {
    flex: 1;
    padding: 20px;
  }
  
  .product-image img {
    width: 100%;
    border-radius: 10px;
    height: auto;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    #container {
      flex-direction: column;
      width: 90%;
    }
  
    .product-details {
      text-align: center;
    }
  
    .product-details .information {
      font-size: 16px;
    }
  
    .product-image img {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .product-details h1 {
      font-size: 28px;
    }
  
    .product-details .quote {
      font-size: 18px;
    }
  
    .product-details .information {
      font-size: 14px;
    }
  }
.signup-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
}

.left-container,
.right-container {
  flex: 1 1 45%; /* Responsive width */
  max-width: 500px; /* Limit max width for readability */
}

.left-container img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.right-container {
  padding: 20px;
  background-color: #f8f9fa; /* Optional background for contrast */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.amount-button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}

.button-label-container {
  flex: 1 1 calc(33% - 15px); /* Adjust width dynamically */
  text-align: center;
}

.checkbox-btn {
  width: 100%;
  padding: 10px;
  border: 1px solid #007bff;
  border-radius: 5px;
  background-color: white;
  color: #007bff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkbox-btn:hover {
  background-color: #007bff;
  color: white;
}

.pets-name,
.pets-name-amount {
  margin-bottom: 20px;
  width: 100%;
}

.pets-name input,
.pets-name textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

footer {
  text-align: center;
  margin-top: 20px;
}

footer .btn-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

footer .btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

footer .btn:hover {
  background-color: #0056b3;
}

/* Media Queries */
@media (max-width: 768px) {
  .left-container,
  .right-container {
    flex: 1 1 100%; /* Stack the sections vertically */
  }

  .amount-button-container {
    gap: 10px;
    justify-content: center;
  }

  .button-label-container {
    flex: 1 1 100%; /* Stack buttons in a single column */
  }

  footer .btn {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .signup-container {
    padding: 10px;
  }

  .right-container {
    padding: 15px;
  }

  .checkbox-btn {
    font-size: 14px;
  }
}
/* General container */
.signup-container {
  display: flex;
  flex-wrap: nowrap; /* Ensure containers stay beside each other */
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

/* Left Section */
.left-container {
  flex: 1 1 25%; /* Occupies 1/4th width */
  max-width: 25%; /* Ensure left side is narrower */
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.left-container h1 {
  font-size: 1.8rem;
  color: #333;
}

.left-container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Right Section */
.right-container {
  flex: 1 1 75%; /* Occupies 3/4th width */
  max-width: 75%; /* Ensure right side is wider */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.right-container header h1 {
  font-size: 1.5rem;
  color: #444;
}

.right-container h2 {
  font-size: 1.4rem;
  color: #444;
  margin: 20px 0;
}

.set {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.pets-name {
  flex: 1;
  min-width: 250px;
}

.pets-name label,
.pets-name input,
.pets-name textarea {
  display: block;
  width: 100%;
  margin-top: 5px;
}

.pets-name textarea {
  resize: vertical;
  height: 100px;
}

.amount-button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}

.button-label-container {
  flex: 1 1 calc(33% - 15px); /* Adjust width dynamically */
  text-align: center;
}

.checkbox-btn {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkbox-btn:hover {
  background: linear-gradient(180deg, #ecc759, #f5d96b, #d49a2d);
  color: black;
}

.pets-name-amount {
  flex: 1 1 100%;
  text-align: center;
  margin-top: 10px;
}

.pets-name-amount input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

footer {
  text-align: center;
  margin-top: 20px;
}

footer .btn-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

footer .btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;
}

footer .btn:hover {
  background: linear-gradient(180deg, #ecc759, #f5d96b, #d49a2d);
}

/* Media Queries */
@media (max-width: 768px) {
  .signup-container {
    flex-wrap: wrap; /* Stack sections on smaller screens */
  }

  .left-container,
  .right-container {
    flex: 1 1 100%; /* Stack vertically */
    max-width: 100%;
  }

  .amount-button-container {
    gap: 10px;
    justify-content: center;
  }

  .button-label-container {
    flex: 1 1 100%; /* Stack buttons in a single column */
  }

  footer .btn {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .signup-container {
    padding: 10px;
  }

  .right-container {
    padding: 15px;
  }

  .checkbox-btn {
    font-size: 14px;
  }
}
/* General container */
.signup-container {
  display: flex;
  flex-wrap: nowrap; /* Ensure containers stay beside each other */
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

/* Left Section */
.left-container {
  flex: 1 1 25%; /* Occupies 1/4th width */
  max-width: 25%; /* Ensure left side is narrower */
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.left-container h1 {
  font-size: 1.8rem;
  color: #333;
}

.left-container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Right Section */
.right-container {
  flex: 1 1 75%; /* Occupies 3/4th width */
  max-width: 75%; /* Ensure right side is wider */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.right-container header h1 {
  font-size: 1.5rem;
  color: #444;
}

.right-container h2 {
  font-size: 1.4rem;
  color: #444;
  margin: 20px 0;
}

.set {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.pets-name {
  flex: 1;
  min-width: 250px;
}

.pets-name label,
.pets-name input,
.pets-name textarea {
  display: block;
  width: 100%;
  margin-top: 5px;
}

.pets-name textarea {
  resize: vertical;
  height: 100px;
}

.amount-button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}

.button-label-container {
  flex: 1 1 calc(33% - 15px); /* Adjust width dynamically */
  text-align: center;
}

.checkbox-btn {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkbox-btn:hover {
  background: linear-gradient(180deg, #ecc759, #f5d96b, #d49a2d);
}

.pets-name-amount {
  flex: 1 1 100%;
  text-align: center;
  margin-top: 10px;
}

.pets-name-amount input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

footer {
  text-align: center;
  margin-top: 20px;
}

footer .btn-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

footer .btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;
}

footer .btn:hover {
  background: linear-gradient(180deg, #ecc759, #f5d96b, #d49a2d);
}

/* Media Queries */
@media (max-width: 768px) {
  .signup-container {
    flex-wrap: wrap; /* Stack sections on smaller screens */
  }

  .left-container,
  .right-container {
    flex: 1 1 100%; /* Stack vertically */
    max-width: 100%;
  }

  .amount-button-container {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }

  .button-label-container {
    flex: 1 1 100%; /* Stack buttons in a single column */
  }

  footer .btn {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .signup-container {
    padding: 10px;
  }

  .right-container {
    padding: 15px;
  }

  .checkbox-btn {
    font-size: 14px;
  }
}

/* Import Google Font */
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600&display=swap");

/* General Reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}



/* Import Google Font */

.ag-format-container {
  width: 1142px;
  margin: 0 auto;
}



.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-decoration: none;
  padding: 50px 0;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);

  margin: 0 15px 30px;
  text-decoration: none;
  overflow: hidden;

  border-radius: 28px;
}
.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  text-decoration: none;
  overflow: hidden;

  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color:black;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  min-height: 87px;
  margin: 0 0 25px;

  overflow: hidden;

  font-weight: bold;
  font-size: 30px;
  color: black;
  text-decoration: none;
  z-index: 2;
  position: relative;
}
.ag-courses-item_date-box {
  font-size: 18px;
  color:black;
  text-decoration: none;
  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;
  text-decoration: none;
  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;
  text-decoration: none;
  border-radius: 50%;

  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}
.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}
.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #e44002;
}
.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #952aff;
}
.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
  background-color: #cd3e94;
}
.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
  background-color: #4c49ea;
}

.body{
  text-decoration: none;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}

  
  /* Custom Animation */
  @keyframes slideIn {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
   
/* Gallery */
.gallery {
    position: relative;
    width: 300px; /* Base width */
    height: 200px; /* Base height */
    transform-style: preserve-3d;
    animation: rotate 35s linear infinite;
    margin: auto; /* Center the gallery */
  }
  
  /* Animation */
  @keyframes rotate {
    from {
      transform: perspective(1200px) rotateY(0deg);
    }
    to {
      transform: perspective(1200px) rotateY(360deg);
    }
  }
  
  /* Gallery Span */
  .gallery span {
    background-color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(calc(var(--i) * 45deg)) translateZ(380px);
  }
  
  /* Gallery Images */
  .gallery span img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .gallery {
      width: 250px; /* Reduce width for larger tablets */
      height: 180px; /* Adjust height proportionally */
    }
  
    .gallery span {
      transform: rotateY(calc(var(--i) * 45deg)) translateZ(300px); /* Adjust translateZ */
    }
  }
  
  @media (max-width: 768px) {
    .gallery {
      width: 200px; /* Reduce width for tablets */
      height: 150px; /* Adjust height proportionally */
    }
  
    .gallery span {
      transform: rotateY(calc(var(--i) * 45deg)) translateZ(250px); /* Further reduce translateZ */
    }
  }
  
  @media (max-width: 480px) {
    .gallery {
      width: 150px; /* Smaller size for mobile */
      height: 100px; /* Adjust height for smaller screens */
    }
  
    .gallery span {
      transform: rotateY(calc(var(--i) * 45deg)) translateZ(200px); /* Reduce translateZ */
    }
  }
  
/* General Footer Styles */
.footer-section {
  background-color: black;
  padding: 20px 0;

}

.footer-logo {
  max-width: 150px;
  height: auto;
}

.footer-para {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 10px;
}

.footer-h5 {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-section .row {
    flex-direction: column;
    align-items: center;
  }

  .footer-section .col-md-6,
  .footer-section .col-md-3 {
    width: 100%;
    text-align: center;
  }

  .footer-logo {
    max-width: 120px;
  }

  .footer-para {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .footer-section {
    padding: 15px 0;
  }

  .footer-logo {
    max-width: 100px;
  }

  .footer-h5 {
    font-size: 14px;
  }

  .footer-para {
    font-size: 12px;
  }
}



