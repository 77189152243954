
@media (min-width: 768px) {
    .btn-h-donate {
      background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
      color: black;
  
      padding: 12px 25px;
      font-size: 15px;
      font-weight: 700;
      border: none;
      margin-top: 30px;
    }
  }
  
  @media (max-width: 767px) {
    .btn-h-donate {
      background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
      color: black;
  
      padding: 7px 10px;
      font-size: 15px;
      font-weight: 700;
      border: none;
      margin-top: 20px;
    }
  }
  
  a {
    text-decoration: none;
  }
  
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .header-donate {
    position: fixed;
    width: 100%;
    top: 0;
    height: 12%;
    z-index: 11111;
    background: linear-gradient(
      181deg,
      #00000099,
      #000000a3,
      #000000a1,
      #00000080
    );
  
    box-shadow: 0px 5px 20px 7px #000000a3;
  }
  
  @media (max-width: 767px) {
    .header-logo {
      width: 60%;
    }
  }
  
  @media (min-width: 768px) {
    .header-logo {
      width: 12%;
      margin-top: -1%;
      margin-left: 2%;
    }
  }
  
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }


/*gitadan_new */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bree+Serif&family=EB+Garamond:ital,wght@0,500;1,800&display=swap');

body {
  background: #dfc2f2;
  background-image: linear-gradient(to right, #ffffb3, #ffe6e6);
  background-attachment: fixed;
  background-size: cover;
  margin: 0;
  padding: 0;
  font-family: 'EB Garamond', serif;
}

#container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 15px 30px 1px grey;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  margin: 5em auto;
  width: 80%;
  max-width: 1200px;
  height: auto;
  padding: 20px;
  flex-wrap: wrap;
}

.product-details {
  flex: 1;
  padding: 20px;
}

.product-details h1 {
  font-family: 'Bebas Neue', cursive;
  font-size: 36px;
  color: #344055;
  margin: 0;
}

.product-details .quote {
  font-size: 20px;
  font-style: italic;
  color: #344055;
  margin: 15px 0;
}

.product-details .information {
  text-align: justify;
  font-size: 18px;
  color: #7d7d7d;
  margin: 10px 0;
}

.product-image {
  flex: 1;
  padding: 20px;
}

.product-image img {
  width: 100%;
  border-radius: 10px;
  height: auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  #container {
    flex-direction: column;
    width: 90%;
  }

  .product-details {
    text-align: center;
  }

  .product-details .information {
    font-size: 16px;
  }

  .product-image img {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .product-details h1 {
    font-size: 28px;
  }

  .product-details .quote {
    font-size: 18px;
  }

  .product-details .information {
    font-size: 14px;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100vh;
  background: #232427;
}

body .gita-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 40px 0;
}

body .gita-container .gita-card {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
}

body .gita-container .gita-box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

body .gita-container .gita-box:hover {
  transform: translateY(-50px);
}

body .gita-container .gita-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

body .gita-container .gita-content {
  padding: 20px;
  text-align: center;
}

body .gita-container .gita-content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

body .gita-container .gita-content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

body .gita-container .gita-content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}
@media screen and (max-width: 768px) {
  h1 {
     /* Smaller font size for tablets */
    word-wrap: break-word;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  h1 {
     /* Even smaller font size for mobile devices */
    word-wrap: break-word;
    text-align: center;
  }
}
  
  /* General container */
  .signup-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 10% auto;
    max-width: 1200px;
    font-family: 'Arial', sans-serif;
  }
  
  /* Left section */
  .left-container {
    flex: 1;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .left-container h1 {
    font-size: 2rem;
    color: #333;
  }
  
  .left-container .puppy img {
    max-width: 100%;
    border-radius: 10px;
  }
  
  /* Right section */
  .right-container {
    flex: 2;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px 30px;
    margin-left: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .right-container header h1 {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 10px;
  }
  
  .left-container h1 p {
    font-size: 1rem;
    color: #666;
  }
  
  .right-container h2 {
    font-size: 1.5rem;
    color: #444;
    margin: 20px 0;
  }
  
  .set {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .pets-name {
    flex: 1;
    min-width: 250px;
    margin-bottom: 15px; /* Ensures consistent spacing between fields */
  }
  
  .pets-name label, .pets-name-amout label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .pets-name input, .pets-name textarea, .pets-name select,
  .pets-name-amout input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 5px;
    box-sizing: border-box; /* Ensures padding and border are included in the element's width */
    height: 40px; /* Uniform height for inputs */
  }
  
  .pets-name input:focus, .pets-name textarea:focus, .pets-name select:focus,
  .pets-name-amout input:focus {
    border-color: #7952b3;
    outline: none;
  }
  
  .pets-name input[type="date"], .pets-name-amout input[type="date"] {
    height: 40px; /* Ensure consistent height for date input */
  }
  
  .pets-name textarea {
    resize: vertical; /* Allows vertical resizing only for textarea */
    height: 100px; /* Set a specific height for the textarea */
  }
  
  .pets-name input[type="tel"] {
    height: 40px; /* Ensure consistent height for phone input */
  }
  
  .amount-button-container {
    display: flex;
    gap: 10px;
  }
  
  .checkbox-btn, .btn, .btn-primary, .pets-name-amout input {
    padding: 10px 15px;
    background-color: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .pets-name-amout input {
    height: 50px;
    padding: 10px 15px;
  }
  
  .set {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .pets-name-amout {
    width: 150px; /* Moves the second div to the left */
  }
  
  
  .checkbox-btn.active,.btn.active ,.btn-primary.active{
    background:linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
    color: black;
    border: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b) ;
  }
  
  .checkbox-btn:hover,.btn:hover,.btn-primary:hover {
    background: linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
  }
  .btn{
    background:linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b);
  }
  
  .amount-label {
    margin-top: 25px;
    font-size: 14px;
    color: black; 
    /* Change as needed */
  }
  
  /* Footer section */
  footer p {
    font-size: 0.9rem;
    color: #666;
  }
  
  footer p a {
    color: #7952b3;
    text-decoration: none;
  }
  
  footer p a:hover {
    text-decoration: underline;
  }
  
  footer {
    text-align: center; /* Center the text */
    margin-top: 20px;
  }
  
  .btn-group {
    display: flex;
    justify-content: center;  /* Horizontally center the button */
    align-items: center;      /* Vertically center the button */
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    .signup-container {
      flex-direction: column;
      align-items: stretch;
    }
  
    .right-container {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  body {
    margin: 0;
    height: 100vh;
    display: grid;
    place-items: center;
   
  }
  /* Card styling */
/* Card Styling */
.card {
  width: 100%;
  height: auto;
  background: #fefcff;
  border: 3px solid white;
  border-radius: 15px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  padding: 20px;
}

.card:hover {
  transform: scale(1.05);
}

/* Text Styling */
.scanText {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
}

.bankDetailsContainer {
  margin-top: 20px;
}

.bankDetailsText {
  font-size: 1rem;
  margin: 5px 0;
  color: #333;
  line-height: 1.5;
}

/* Image Styling */
.qrImage {
  width: 100%;
  height: auto;
  max-width: 250px;
  margin-top: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Receipt Text */
.receiptText {
  text-align: center;
  font-size: 0.9rem;
  color: #555;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .scanText {
    font-size: 1rem;
  }
  .bankDetailsText {
    font-size: 0.9rem;
  }
  .qrImage {
    max-width: 200px;
  }
}

.galleryi{
    --s: 150px; /* control the size */
    --g: 10px;  /* control the gap */
    --f: 1.5;   /* control the scale factor */
    
    display: grid;
    gap: var(--g);
    width: calc(3*var(--s) + 2*var(--g));
    aspect-ratio: 1;
    grid-template-columns: repeat(3,auto);
    transform: rotate(0deg);
    justify-items: center; 
    margin: 0 auto; 
    width: 80%;
  }
  
  .galleryi > img {
    width: 0;
    height: 0;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    cursor: pointer;
    filter: grayscale(80%);
    transition: .35s linear;
    transform: rotate(0deg);
  }
  
  .galleryi img:hover{
    filter: grayscale(0);
    width:  calc(var(--s)*var(--f));
    height: calc(var(--s)*var(--f));
    transform: rotate(0deg);
  }
  
  

  